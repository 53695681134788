<template>
	<div class="dashboard-container">
		<admin />
		
		<el-tooltip placement="top" :content="$t('general.back_to_top')">
			<backtotop :visibility-height="300" :back-position="50" transition-name="fade" />
		</el-tooltip>
	</div>
</template>

<script>
import {getLocalStorage} from '@/system/store/localstorage';
import backtotop from '@/components/backtotop';
import admin from './admin';

export default{
	components: { backtotop, admin },
	data() {
		return {
			jobID: '',
		}
    },
	created:function(){
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
		this.jobID = getLocalStorage('job_id');
	}
}
</script>